import { useState, useEffect } from "react";
import { css } from "@emotion/react";
import Image from "next/image";
import dynamic from "next/dynamic";
import { useMediaQuery } from "react-responsive";
import { useIsOnboardingTestVariant } from "../../hooks/useIsOnboardingTestVariant";
import { trackStructEvent } from "@snowplow/browser-tracker";
import { getGoogleAnalyticsClientId } from "../../utils/getGoogleAnalyticsClientId";

import {
  colors,
  breakpoints,
} from "@mytutor/shared-react-web-components/lib/theme";
import { PrimaryCTAButton } from "@mytutor/shared-react-web-components/lib/PrimaryCTA";
const SubjectPickerDropdown = dynamic(() => import("../SubjectPickerDropdown"));

const heroStyle = css`
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  margin: 70px auto 0;
  padding: 100px 0 107px;
  max-width: 1084px;
  @media (max-width: ${breakpoints.largeDesktop}px) {
    padding: 88px 0;
    gap: 0px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column-reverse;
    max-width: 550px;
    padding: 40px 0 12px;
  }
  @media (max-width: ${breakpoints.phone}px) {
    padding: 24px 0 12px;
  }

  h1 {
    font-family: "BureauGrotesk", sans-serif;
    font-size: 4em;
    font-weight: 400;
    line-height: 1;
    color: ${colors.neutral8};
    margin: 0;
    @media (max-width: ${breakpoints.desktop}px) {
      font-size: 3em;
    }
    @media (max-width: ${breakpoints.tablet}px) {
      font-size: 36px;
      width: 100%;
    }
  }

  p {
    font-weight: 400;
    line-height: 1.72rem;
    color: ${colors.neutral8};
    margin: 40px 50px 25px 0;
    @media (max-width: ${breakpoints.largeDesktop}px) {
      margin: 24px 24px 40px 0;
    }
    @media (max-width: ${breakpoints.tablet}px) {
      margin: 16px 0 24px 0;
    }
  }

  section {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    min-width: 345px;
    @media (max-width: ${breakpoints.largeDesktop}px) {
      padding: 0 0 0 60px;
    }
    @media (max-width: ${breakpoints.desktop}px) {
      padding: 0 0 0 40px;
    }
    @media (max-width: ${breakpoints.tablet}px) {
      padding: 32px 16px 32px 16px;
      width: 100%;
      align-items: center;
    }
    @media (max-width: ${breakpoints.phone}px) {
      padding: 16px;
    }
  }

  aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    position: relative;
    max-width: 580px;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: ${breakpoints.tablet}px) {
      width: 100%;
      margin-top: 60px;
      margin-bottom: 40px;
    }
    @media (max-width: ${breakpoints.phone}px) {
      display: none;
    }
  }

  .brand-image {
    z-index: 2;
    width: 60%;
    height: 60%;
    max-width: 608px;
  }

  .brand-shape1 {
    width: 45%;
    max-width: 256px;
    top: -30%;
    left: 9%;
    z-index: 1;
    position: absolute;
  }

  .brand-shape2 {
    width: 45%;
    max-width: 256px;
    top: 17%;
    left: 45%;
    z-index: 1;
    position: absolute;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .subject-picker {
    display: flex;
    width: 70%;
    margin: 0 50px 0 0;

    @media (max-width: ${breakpoints.largeDesktop}px) {
      width: 90%;
    }
    @media (max-width: ${breakpoints.desktop}px) {
      width: 100%;
    }
    @media (min-width: ${breakpoints.desktop}px) {
      min-width: 380px;
    }
    @media (max-width: ${breakpoints.tablet}px) {
      width: 100%;
      display: flex;
      gap: 16px;
      flex-direction: column;
      margin: 0;
    }
    font-family: "FoundersGrotesk";
    font-size: 1em;
    font-weight: 300;
  }

  .subject-picker > div {
    width: 50%;
    @media (max-width: ${breakpoints.tablet}px) {
      width: 100%;
    }
  }

  .subject-picker > button {
    width: 40%;
    @media (max-width: ${breakpoints.tablet}px) {
      margin-top: 20px;
      width: 100%;
    }
  }
`;

type Level = {
  name: string;
  urlName: string;
};

type Subject = {
  subject: string;
  urlSubject: string;
  levels: Level[];
};

type Props = {
  header: string;
  subText: string;
  subjects: Subject[];
  ctaText: string;
};

const Hero = ({ header, subText, subjects, ctaText }: Props) => {
  const [subject, setSubject] = useState<string | null>(null);
  const [isSubjectPickerFocused, setIsSubjectPickerFocused] = useState(false);
  const isVariant = useIsOnboardingTestVariant();

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.phone}px)`,
  });

  const handleSubjectSubmit = () => {
    const path = isVariant ? "onboarding/" : "";
    const url = `${process.env.NEXT_PUBLIC_SIGNUP_URL}${path}`;

    if (subject) {
      window.location.assign(`${url}?subject=${subject}`);
      return;
    }
    window.location.assign(url);
  };

  useEffect(() => {
    trackStructEvent({
      category: "onboarding_test",
      property: "google_analytics_id",
      action: "page_load",
      label: getGoogleAnalyticsClientId(document.cookie),
    });
    trackStructEvent({
      category: "onboarding_test",
      property: "abtasty_bucket",
      action: "page_load",
      label: isVariant ? "variant" : "control",
    });
  }, [isVariant]);

  return (
    <main css={heroStyle}>
      <section>
        {!(isSubjectPickerFocused && isMobile) && (
          <>
            <h1>{header}</h1>
            <p>{subText}</p>
          </>
        )}

        <div className="subject-picker">
          <div>
            <div data-test="subjectPicker">
              <SubjectPickerDropdown
                subjects={subjects}
                onChange={(e: any) => {
                  //set it empty because if not, not possible to clear on mobile
                  if (!e) {
                    return setSubject("");
                  }
                  return setSubject(e.value);
                }}
                onFocus={() => {
                  setIsSubjectPickerFocused(true);
                }}
                onBlur={() => {
                  setIsSubjectPickerFocused(false);
                }}
              />
            </div>
          </div>
          <div>
            <PrimaryCTAButton
              onClick={handleSubjectSubmit}
              data-test="submitFindATutor"
            >
              {ctaText}
            </PrimaryCTAButton>
          </div>
        </div>
      </section>
      <aside>
        <div className="brand-image">
          <Image
            src="/landing/brand-image-for-hero.png"
            alt="Picture of a student"
            width={583}
            height={407}
          />
        </div>
        <div className="brand-shape1">
          <img src="/landing/brand-shape1.svg" alt="Picture of a student" />
        </div>
        <div className="brand-shape2">
          <img src="/landing/brand-shape2.svg" alt="Picture of a student" />
        </div>
      </aside>
    </main>
  );
};

export default Hero;
